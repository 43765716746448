<template>
    <div v-loading.fullscreen.lock="loading" :element-loading-text="loadingText"
        element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">

        <div id="overlay">
            <div id="loader" class="loader"></div>
        </div>
        <div id="themeLoader" v-loading.fullscreen.lock="themeLoader" :element-loading-text="loadingText"
            element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)"></div>
        <div>
            <b-navbar toggleable="lg" type="dark" variant="info" id="TopBar" :style="companyTheme">
                <b-navbar-brand href="#">
                    <img v-if="this.companyInfo.logo" :src="this.companyInfo.logo" :alt="this.companyInfo.name"
                        style="max-height: 34px; max-width: 150px" />
                    <img v-else src="https://kodefast.com/images/KODEFAST1.png" :alt="this.companyInfo.name"
                        style="max-height: 34px; max-width: 150px" />
                </b-navbar-brand>

            </b-navbar>
        </div>
        <div class="payment-link-card" id="paymentBox" v-loading.fullscreen.lock="!activePaymentSystem"
            element-loading-text="Please wait .... " element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)">
            <b-container>
                <b-card :header="`Payment Request from ${companyInfo.name}`">
                    <b-card-text>
                        We've received a payment request from
                        <b-spinner label="Spinning" variant="outline-dark" :style="companyTextStyles"
                            v-if="!companyInfo"></b-spinner><b v-else>{{ companyInfo.name }} </b> totaling
                        <b-spinner label="Spinning" variant="outline-dark" :style="companyTextStyles"
                            v-if="!totalPaymentAmount"></b-spinner><b v-else>{{
        getCurrencySymbol(transactionInfo.currency) }} {{ totalPaymentAmount }}</b>, and we're
                        verifying its legitimacy before processing the transaction.
                    </b-card-text>

                    <b-table-simple hover small caption-top responsive>
                        <b-tbody>
                            <b-tr>
                                <b-td>Total Amount</b-td>
                                <b-td>
                                    <b-spinner label="Spinning" variant="outline-dark" :style="companyTextStyles"
                                        v-if="!totalPaymentAmount"></b-spinner><b v-else>{{
        getCurrencySymbol(transactionInfo.currency) }}
                                        {{ totalPaymentAmount }} </b></b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>
                                    <b-button v-b-toggle.collapse-1 variant="link">Received Amount</b-button>
                                    <b-collapse id="collapse-1" class="mt-2">
                                        <b-card style="max-width: 60%">
                                            <b-table hover :items="lastTransactions" :fields="fields">
                                                <template #cell(SNo)="data">
                                                    {{ data.index + 1 }}
                                                </template>
                                                <template #cell(paymentDate)="data">
                                                    {{ $moment.utc(data.item.paymentDate).local().format('MM-DD-YYYY hh:mm:ss A') }}
                                                </template>
                                                <template #cell(paidAmount)="data">
                                                    {{ getCurrencySymbol(data.item.currency) }} {{
        data.item.paidAmount }}

                                                </template>
                                            </b-table>
                                        </b-card>
                                    </b-collapse>
                                </b-td>
                                <b-td>
                                    <b>{{ getCurrencySymbol(transactionInfo.currency) }}
                                        {{ totalPaidAmount }} </b>
                                </b-td>
                            </b-tr>

                        </b-tbody>
                        <b-tr>
                            <b-td>Due Amount</b-td>
                            <b-td>
                                <!-- <b-spinner label="Spinning" variant="outline-dark" :style="companyTextStyles" v-if="!balanceAmount"></b-spinner> -->
                                <b>{{ getCurrencySymbol(transactionInfo.currency) }}
                                    {{ parseFloat(balanceAmount).toFixed(2) }} </b></b-td>
                        </b-tr>
                    </b-table-simple>

                    <div v-if="balanceAmount > 0">
                        <div>
                            <strong>Make Payment Through</strong>
                        </div>
                        <hr />
                        <span class="bt" v-if="payButtonDetails &&  payButtonDetails.validations && payButtonDetails.validations.paymentSystems && fetchMainDataStepStatus==2" >
                            <span v-for="(listedPG, index) in payButtonDetails.validations.paymentSystems" :key="index">
                                <button type="button" class="btn btn-sm btn-outline-default m-1" @click="validatePaymentRequest(listedPG)" v-if="getNameOfPG(listedPG)">
                                    <span style="color:#ffffff;" >{{ getNameOfPG(listedPG).pgType }} </span>
                                    <svg class="gateway_icon" viewBox="0 0 100 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <component :is="getGatewayIcon(getNameOfPG(listedPG).pgType)" />
                                    </svg>
                                </button>
                            </span>
                        </span>
                    </div>
                    <span class="stamp is-approved" style="float:right" v-else>Paid</span>
                </b-card>
            </b-container>

            <dialog-component v-loading="loading" title="" :visible="otherPayments" @before-close="handleCancel"
                :containerWidth="'90vw'" :containerMaxHeight="'98vh'" :isShowFooter="false" :isShowHeader="false"
                :cancelConfirmation="true">
                <b-container>
                    <b-row>
                        <b-col lg="6" md="12">
                            <b-form-group id="customer-email" label="Email" label-for="customer-email"
                                valid-feedback=" " :invalid-feedback="emailValidation" :state="emailState">
                                <b-form-input id="customer-email" v-model="email" :state="emailState"
                                    placeholder="Enter email" trim></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col lg="6" md="12">
                            <b-form-group id="customer-street" label="Address" label-for="customer-street"
                                valid-feedback=" ">
                                <b-form-input id="customer-street" v-model="cust_street" placeholder="Address"
                                    trim></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col lg="6" md="12">
                            <b-form-group id="customer-city" label="City" label-for="customer-city" valid-feedback=" ">
                                <b-form-input id="customer-street" v-model="cust_city" placeholder="City"
                                    trim></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col lg="6" md="12">
                            <b-form-group id="customer-state" label="State" label-for="customer-state"
                                valid-feedback=" ">
                                <b-form-select v-model="cust_state" id="customer-state" placeholder="State"
                                    :options="states"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col lg="6" md="12">
                            <b-form-group id="customer-zip-code" label="Zip code" label-for="customer-zip-code"
                                valid-feedback=" " :invalid-feedback="zipValidation" :state="zipState">
                                <b-row>
                                    <b-col lg="2" md="2" sm="2">
                                        <vue-country-dropdown @onSelect="onSelect"
                                            :preferredCountries="['US', 'IN', 'CA']">
                                            :disabledFetchingCountry="false"
                                            :defaultCountry="country"
                                            :defaultCountryByAreaCode="90"
                                            :immediateCallSelectEvent="true"
                                            :enabledFlags="true"
                                            :enabledCountryCode="true"
                                            :showNameInput="true"
                                            :showNotSelectedOption="true"
                                            :notSelectedOptionText="'Not Selected'"
                                        </vue-country-dropdown>
                                    </b-col>
                                    <b-col lg="10" md="10" sm="10">
                                        <b-form-input id="customer-zip-code" v-model="zipCode" :state="zipState" trim
                                            placeholder="Zip Code"></b-form-input>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="12" md="12">
                            <b-card no-body>
                                <b-tabs v-model="tabIndex" small card>
                                    <b-tab title="Credit Card">
                                        <VCreditCard @change="creditInfoChanged" @cardChanged="cardType" direction="row"
                                            :yearDigits="4" />
                                    </b-tab>
                                    <b-tab title="ACH" disabled>
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="BankName">Bank Name</label>
                                                        <input type="text" class="form-control" id="BankName"
                                                            placeholder="Bank Name">
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="AccountHolderName">Account Holder Name</label>
                                                        <input type="text" class="form-control" id="AccountHolderName"
                                                            placeholder="Account Holder Name">
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="BankAccountNumber">Bank Account Number</label>
                                                        <input type="text" class="form-control" id="BankAccountNumber"
                                                            placeholder="Bank Account Number">
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="RoutingNumber">Routing Number</label>
                                                        <input type="text" class="form-control" id="RoutingNumber"
                                                            placeholder="Bank Account Number">
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="AccountType">Account Type</label>
                                                        <select class="form-control" id="AccountType"
                                                            placeholder="Account Type">
                                                            <option>CHECKING</option>
                                                            <option>SAVING</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="checkbox">
                                                <label><input type="checkbox" name="remember"> Remember me</label>
                                            </div>
                                        </div>

                                    </b-tab>
                                </b-tabs>
                            </b-card>
                        </b-col>
                    </b-row>
                    <br />
                    <b-row>
                        <b-col>
                            <button id="PayButton" class="btn btn-block btn-success submit-button m-lr-1" type="button"
                                :style="companyTheme" @click="commonCCPayment">
                                <span class="submit-button-lock"></span>
                                <span class="align-middle">Pay {{ getCurrencySymbol(transactionInfo.currency) }}
                                    {{ userEnteredAmount }} </span>
                            </button>
                        </b-col>
                    </b-row>
                    <br />
                    <br />
                    <br />
                </b-container>

            </dialog-component>


            <dialog-component v-loading="loading" title="Partial payment" :visible="partialPaymentVisible"
                @before-close="handleClose" :containerWidth="'50vw'" :containerMaxHeight="'70vh'" :isShowFooter="true">
                <template>
                    <el-radio v-model="partialPaymentStatus" :label="1">Pay Full amount</el-radio>
                    <el-radio v-model="partialPaymentStatus" :label="0">Pay partial amount</el-radio>
                    <br /> <br />
                    <el-input-number v-if="!partialPaymentStatus" :precision="2" v-model="userEnteredAmount" :min="1"
                        :max="balanceAmount" placeholder="Enter other amount " />
                </template>
                <span slot="footer" class="dialog-footer">
                    <div>
                        <el-button type="primary" size="mini" @click="payInitiate">
                            Proceed to Pay
                        </el-button>
                    </div>
                </span>
            </dialog-component>

            <!-- Authentication  dialog for swirepay Pay method-->
            <dialog-component v-loading="loading" title="" :visible="payMethodValidationPopUp" :containerWidth="'50vw'"
                :containerMaxHeight="'50vh'" :isShowFooter="false" :isShowHeader="false">
                <b-alert show variant="info">
                    <h4 class="alert-heading">Attention!</h4>
                    <p>
                        Your payment method validation requires action. Please click on <b>“Authenticate”</b> button to
                        verify your
                        payment method and you will be redirected to a secure page.
                    </p>
                    <p variant="danger" style="color:red">
                        <b>NOTE:</b> After successful verification, kindly proceed by clicking on the <b>“Proceed”</b>
                        button to
                        complete the payment process.
                    </p>
                    <hr>
                    <p class="mb-0">
                        <b-button variant="outline-warning" :href="payMethodValidationLink"
                            v-if="payMethodValidationLink" target="_blank">Authenticate</b-button> <b-button
                            variant="outline-success" @click="validateSession">
                            Proceed
                        </b-button>
                    </p>
                </b-alert>
            </dialog-component>
        </div>
        <br /><br />
        <div id="receipt">
            <b-container>

                <b-jumbotron>

                    <template #lead>
                        <b-row>
                            <b-col sm="12" md="12" lg="8">Receipt : <span style="font-weight:bold;" id="transactionReferenceId"></span></b-col><b-col sm="12" md="12" lg="4"><span class="stamp is-approved" id="statusMsg"></span></b-col>
                        </b-row>
                    </template>
                    <hr class="my-4">
                    <div class="text-center" id="transactionInfoMsg">
                    </div>
                </b-jumbotron>

            </b-container>
        </div>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import axios from "axios";
import config from "../../config/app";
import moment from "moment";
import VCreditCard from './CreditCard';
import VueCountryDropdown from 'vue-country-dropdown';
var currencyToSymbolMap = require("./CurrencySymbols.json");
var statesList = require(`./states.json`);
import * as PaymentIcons from "../templates/PaymentIcons/index";
export default {
    name: "payment-link",
    components: { VCreditCard, VueCountryDropdown },
    data() {
        return {
            /* Payment status  Fetching  variables deceleration */
            fetchMainDataStepStatus:0,
            dataId: '',
            entityId: '',
            templateId: '',
            loader: false,
            loading: false,
            loadingText: '',
            email: '',
            zipCode: '',
            tabIndex: 1,
            country: 'US',
            customerName: '',
            cust_street: '',
            cust_city: '',
            cust_state: '',
            states: [],
            paymentAccountId: '',
            transactionAccountId: '',
            transactionInfo: {},
            applicationGateway: 'http://localhost:3003/v1.0',
            entityInfo: {},
            dataInfo: {},
            companyInfo: {},
            pgAccountInfo: {},
            activePaymentSystem: {},
            payButtonDetails: {},
            paymentReferenceKey: '',
            partialPaymentVisible: false,
            partialPaymentStatus: 1,
            totalPaymentAmount: 0,
            totalPaidAmount: 0,
            balanceAmount: 0,
            userEnteredAmount: 0,
            lastTransactions: [],
            fields: ["SNo", "paymentDate", "paidAmount"],
            cardComponent: {},
            otherPayments: false,
            companyTheme: '',
            themeLoader: false,
            customer: {
                customerId: '',
                env: 0,
                orgId: '',
                locId: '',
                first_name: '',
                ba_email: '',
            },
            ccPayMethod: {
                payMethodId: '',
                env: 0,
                orgId: '',
                locId: '',
                cstoken: '', //this.customerToken,
                notes: '',
                name_on_card: '',
                card_type: '',
                account_number: '',
                expire_month: '',
                expire_year: '',
                card_verification_value: '',
                billing_address: {
                    city: '',
                    countryCode: 'US',
                    state: "NY",
                    street: '',
                    postalCode: ''
                }
            },
            checkoutInfo: {
                env: 0,
                orgId: '',
                locId: '',
                action: '',  //CARD ,ACH_LEGACY , for forte: sale
                authorization_amount: 0,
                currencyCode:'',
                paymethod_token: '',
                paymentType: '', //echeck
                sec_code: ""    //WEB for forte
                // paymentType: payMentType,
                // sec_code: ''
            },
            payMethodValidationPopUp: false,
            payMethodValidationLink: '',




        }
    },
    computed: {
        companyStyles() {
            if (this.companyInfo && this.companyInfo.button && this.companyInfo.button.background) {
                return `background-color: ${this.companyInfo.button.background} !important;border-color:${this.companyInfo.button.background} !important;`
            }
            else {
                return '';
            }
        },
        companyTextStyles() {
            if (this.companyInfo && this.companyInfo.button && this.companyInfo.button.background) {
                return `color: ${this.companyInfo.button.background} !important;`
            }
            else {
                return '';
            }
        },
        tableBorder(){
            if (this.companyInfo && this.companyInfo.button && this.companyInfo.button.background) {
                return `width: 100%;border: 1px solid ${this.companyInfo.button.background} !important;`
            }
            else {
                return 'width: 100%;border: 1px solid #000000 !important;';
            }
        },
        tableCellBorder(){
            if (this.companyInfo && this.companyInfo.button && this.companyInfo.button.background) {
                return `border: 1px solid ${this.companyInfo.button.background} !important;`
            }
            else {
                return 'border: 1px solid #000000 !important;';
            }
        },
        emailState() {
            if (/^[^@]+@\w+(\.\w+)+\w$/.test(this.email)) {
                return true;
            }
            return false;
        },
        nameState() {
            if (/^(\w\w+)\s(\w+)$/.test(this.customerName)) {
                return true;
            }
            return false;
        },
        nameValidation() {
            if (!/^(\w\w+)\s(\w+)$/.test(this.customerName)) {
                return "Enter valid Name"
            }
            return 'Please enter Name.'
        },
        emailValidation() {
            if (!/^[^@]+@\w+(\.\w+)+\w$/.test(this.email)) {
                return "Enter valid email"
            }
            return 'Please enter Email.'
        },
        zipState() {
            // if (/^[^@]+@\w+(\.\w+)+\w$/.test(this.email)) {
            //     return true;
            // }
            if (this.zipCode.length >= 5 && this.zipCode.length <= 6) {
                return true;
            }
            return false;
        },
        zipValidation() {

            // if (this.email.length > 0) {
            //   return 'Enter at least 4 characters.'
            // }
            // if (!/^[1-9][0-9]{5}$/.test(this.email)) {
            //     return "Enter valid Zip code";
            // }
            return 'Please enter Zip code.'
        },
        getCurrencySymbol() {
            return (currency) => {
                return currencyToSymbolMap[currency];
            }

        },
        getStates() {
            return (state) => {
                return statesList[state] ? statesList[state] : [];
            }
        },
        getNameOfPG() {
            return (pgID) => {
                let pg = this.pgAccountInfo && this.pgAccountInfo.cred_details.filter((pg) => pg._id == pgID);
                return pg && pg[0] ? pg[0] : {}
            }
        },
        getGatewayIcon() {
            return (pgID) => {
                return PaymentIcons[pgID] ? PaymentIcons[pgID] : '';
            }
        }
    },
    methods: {
        async errorPopup(message, title) {
            this.$bvModal.msgBoxOk(message, {
                title: title,
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true
            })
                .then(value => {
                    // this.boxTwo = value
                    console.log(value);

                })
                .catch(err => {
                    console.log("errorPopup",err)

                    // An error occurred
                })
        },
        async getCompanyTheme() {
            this.loading = true;
            this.loadingText = "Customizing the theme....";
            const loader = document.getElementById("overlay");
            loader.style.display = "block";
            this.companyTheme = '';
            this.companyTheme = this.companyStyles;

            loader.style.display = "none";
            // this.loading = false;
            this.loadingText = "Loading....";
            setTimeout(() => {
                this.loading = false;
                this.themeLoader = false;
            }, 1500)
        },
        async fetchGatewayInfo(paymentAccountId) {
            if (paymentAccountId) {
                try {
                    this.loading = true;
                    this.loadingText = "Fetching Payment Gateway Information...";
                    let info = await axios.get(`${config.PAYMENT_GATEWAY_URL}/getAccInfo/${paymentAccountId}`, {
                        headers: {
                            'origin': config.PAYMENT_GATEWAY_URL,
                            'access-control-allow-origin': config.PAYMENT_GATEWAY_URL,
                        }
                    });
                    this.pgAccountInfo = info.data.data;
                    this.loading = false;
                    this.loadingText = "Loading";
                    this.fetchMainDataStepStatus=this.fetchMainDataStepStatus+1;
                }
                catch (error) {
                    console.log("fetchGatewayInfo",error);
                }
            }
        },
        creditInfoChanged(values) {
            if (values) {
                this.ccPayMethod.name_on_card = values.name;
                this.customer.first_name = values.name;
                this.ccPayMethod.card_type = values.cardType;
                this.ccPayMethod.account_number = values.cardNumber.replace(/\s+/g, '');
                this.ccPayMethod.card_verification_value = values.security;
                let exp_mm_yyyy = values && values.expiration && values.expiration.split("/");
                this.ccPayMethod.expire_month = exp_mm_yyyy && exp_mm_yyyy[0];
                this.ccPayMethod.expire_year = exp_mm_yyyy && exp_mm_yyyy[1];
            }
            else {
                this.ccPayMethod.name_on_card = '';
                this.ccPayMethod.card_type = '';
                this.ccPayMethod.account_number = '';
                this.ccPayMethod.card_verification_value = '';
                this.ccPayMethod.expire_month = '';
                this.ccPayMethod.expire_year = '';
                this.customer.first_name = '';
            }

        },
        cardType(val) {
            console.log(val)
        },
        async getLocation() {
            this.loading = true;
            this.loadingText = "Finding your location...";
            let data = await axios.get('https://ipapi.co/json/');
            let locationInfo = data.data;
            this.zipCode = locationInfo.postal;
            this.country = locationInfo.country_code;
            this.states = this.getStates(this.country);
            this.loading = false;
            this.loadingText = "";
        },
        async callEntityApi(entityId) {
            try {
                this.loading = true;
                this.loadingText = "Fetching Entity Information...";
                const response = await axios.get(`${config.API_URL}/getEntity/${entityId}?p=true`);
                if (response.status === 200) {
                    this.entityInfo = response.data.data;

                    // await Promise.all([
                    await this.getCompanyInfo(this.entityInfo.company_id);
                    await this.getPaymentButtonInfo();
                    // ])
                }
                else {
                    this.entityInfo = {};
                }
                this.loading = true;
                this.loadingText = '';
            }
            catch (error) {
                this.entityInfo = {};
                console.log("callEntityApi",error)
            }

        },
        async callEntityDataApi(entityDataId) {
            try {
                this.loading = true;
                this.loadingText = "Loading Data...";
                const response = await axios.get(`${config.API_URL}/getDataById/${entityDataId}`);
                if (response.status === 200) {
                    this.dataInfo = response.data.data

                    this.transactionsInfo(this.dataInfo, this.templateId, this.transactionInfo.key, this.transactionInfo.reference_key);

                }
                else {
                    this.dataInfo = {};
                }
                this.loading = true;
                this.loadingText = "";
            }
            catch (error) {
                this.dataInfo = {};
                console.log("callEntityDataApi",error)
            }
        },
        onSelect({ name, iso2, dialCode }) {
            console.log(name, iso2, dialCode);
            this.states = this.getStates(iso2);
        },
        async getCompanyInfo(companyId) {
            try {
                this.loading = true;
                this.loadingText = "Fetching Company Information...";
                this.companyTheme = 'z-index:1;';
                const response = await axios.get(`${config.API_URL}/company-logo/${companyId}`);
                if (response.status === 200) {
                    this.companyInfo = response.data.info
                    if (this.companyInfo && this.companyInfo.logo) {
                        this.companyInfo.logo = `${config.S3_BUCKET_URL}/${this.companyInfo.logo}`;
                    }
                    await this.getCompanyTheme();
                    this.loading = false;
                    this.loadingText = "";
                }
                else {
                    this.companyInfo = {};
                    await this.getCompanyTheme();
                    this.loading = false;
                    this.loadingText = "";
                }

            }
            catch (error) {
                this.companyInfo = {};
                console.log("getCompanyInfo",error)
            }
        },
        async getPaymentButtonInfo() {
            this.loading = true;
            this.loadingText = "Please wait.... Fetching  Payment Button Information & configuration";
            let payButtonTemplate = this.entityInfo && this.entityInfo.templates && this.entityInfo.templates.filter((template) => {
                return template.template_id == this.templateId
            });
            let allFields = payButtonTemplate && payButtonTemplate[0] && payButtonTemplate[0].templateInfo && payButtonTemplate[0].templateInfo.sections && payButtonTemplate[0].templateInfo.sections[0] && payButtonTemplate[0].templateInfo.sections[0].fields;
            let paymentField = allFields && await allFields.filter((field) => {
                return field.key == this.transactionInfo.key
            })
            this.payButtonDetails = paymentField && paymentField[0];
            this.paymentReferenceKey = this.payButtonDetails && this.payButtonDetails.validations && this.payButtonDetails.validations.entityVariable && this.payButtonDetails.validations.entityVariable.key
            this.loading = false;
            this.loadingText = "";
            this.fetchMainDataStepStatus=this.fetchMainDataStepStatus+1;
        },
        async razorPaySettings() {

            this.loading = true;
            this.loadingText = "Please wait..... Establishing connection with gateway...";

            const res = await this.loadScript(
                'https://checkout.razorpay.com/v1/checkout.js'
            );
            if (!res) {
                alert('Razorpay SDK failed to load. Are you online?');
                return;
            }
            let data = {
                amount: this.userEnteredAmount * 100,
                fee: 0,
                currency: this.transactionInfo.currency,
                description: `Payment Request from ${this.transactionInfo.companyName}`,
                // redirectUrl: this.data.validations && this.data.validations.defaultRedirectUrl ? Config.APP_URL + this.data.validations.defaultRedirectUrl : location,
                modes: ['CARD'],
                // optionItems: 'Payment Request from '+transactionData.companyName,,
                // customer: customer
            }
            const result = await axios.post(`${config.PAYMENT_GATEWAY_URL}/razorpay/order?pgAccountId=${this.activePaymentSystem.paymentAccountId}&txnAcId=${this.transactionAccountId}`, data);

            if (!result) {
                alert('Server error. Are you online?');
                return;
            }
            this.loading = false;
            this.loadingText = "";

            const { amount, id: order_id, currency } = result.data;
            const acc = `pgAccountId=${this.paymentAccountId}&txnAcId=${this.transactionAccountId}`;
            let params = {
                // ...data.notes,
                status: "SUCCESS",
                amount: this.totalPaymentAmount,
                formBuilderId: "",
                currency: this.transactionInfo.currency,
                entityId: "",
                location: "",
                type: "",
                templateId: this.transactionInfo.templateId,
                paymentInit: this.userEnteredAmount,
                entityDataId: this.transactionInfo.entityDataId,
                key: this.transactionInfo.key,
                updateDataKey: this.transactionInfo.updateDataKey,
                formBuilderDataId: "",
                txnDetails: {}
            }
            const options = {
                key: this.activePaymentSystem.publicKey, // Enter the Key ID generated from the Dashboard
                amount: amount.toString(),
                currency: currency,
                name: this.transactionInfo.companyName,
                description: `Payment Request from ${this.transactionInfo.companyName}`,
                image: this.companyInfo?.logo || "https://kodefast.com/images/KODEFAST1.png",
                order_id: order_id,
                handler: async function (info) {
                    this.loading = true;
                    this.loadingText = "Verifying the payment...... Please wait ......";

                    /* Custom Loader  Start*/

                    const loader = document.getElementById("overlay");
                    loader.style.display = "block";

                    this.partialPaymentVisible = false;
                    this.partialPaymentStatus = 1;

                    /* Custom Loader end */

                    const successData = await axios.get(`${config.PAYMENT_GATEWAY_URL}/razorpay/verify/${info.razorpay_payment_id}?${acc}`);
                    if (successData.status === 200) {
                        let data = successData.data
                        params = { ...params, ...data.notes };
                        let transaction = {
                            txn_id: data.id,
                            gateway: "RAZORPAY",
                            paymentId: data.order_id,
                            name: '',
                            email: data.email,
                            phoneNumber: data.contact,
                            paymentSession: info.razorpay_signature,
                            amount: data.amount || 0,
                            amountRefunded: data.amount_refunded || 0,
                            description: "",
                            paymentDate: moment.utc(),
                            refundDate: '',
                            status: data.status == "captured" ? 'Paid' : 'Not Paid',
                            paymentType: data.method || "CARD",
                            method: data.method || '',
                            currency: data.currency || 'USD'
                        }
                        params.txnDetails = transaction;
                        this.loading = true;
                        this.loadingText = "Updating the payment information...Please wait ......";
                        const apiResponse = await axios.post(`${config.API_URL}/updateRulesOfPayBtn`, params, {
                            headers: {
                                usertimezone: new Date().getTimezoneOffset()
                            }
                        });
                        if (apiResponse.data) {
                            //Api data updated
                            this.partialPaymentVisible = false;
                            this.partialPaymentStatus = 1;
                            this.userEnteredAmount = 0;
                            document.getElementById("transactionReferenceId").innerHTML = transaction.txn_id;
                            document.getElementById("statusMsg").innerHTML='Paid';
                            let success = `<p style="text-align:justify">We're excited to inform you that your payment has been successfully processed! 🚀</p>
                        <br /> 
                        <table style="width: 100%;border: 1px solid #784596;"><tr><th style="border: 1px solid #784596;" colspan="2">Payment Details </th></tr>
                           <tr><th  style="border: 1px solid #784596;"> Amount</th><td  style="border: 1px solid #784596;">  ${transaction.currency} ${ transaction.amount/100} </td></tr>
                            <tr><th  style="border: 1px solid #784596;">  Transaction ID</th><td  style="border: 1px solid #784596;">${ transaction.paymentId}</td></tr>
                            <tr><th  style="border: 1px solid #784596;">  Payment Method </th><td  style="border: 1px solid #784596;"> ${ transaction.paymentType}</td></tr>
                            </table>
                            <br/>
                        <p style="text-align:justify">Thank you for choosing us! Your support means the world to us. If you have any questions or need further assistance, feel free to reach out. Our team is here to help!</p><br />
                        `;
                            document.getElementById("transactionInfoMsg").innerHTML = success;

                            document.getElementById("paymentBox").style.display = "none";
                            document.getElementById("receipt").style.display = "block";
                            const loader = document.getElementById("overlay");
                            loader.style.display = "none";
                            setTimeout(() => {
                                this.loading = false;
                                this.loadingText = "";
                            }, 3000);

                        }
                    }
                    else {
                        alert(successData.status + ' Error');
                    }
                },
                prefill: {
                    // name: customer.name,
                    // email: customer.email,
                    // contact: customer.phoneNumber,
                },
                notes: {
                    address: '',
                },
                theme: {
                    color: this.companyInfo && this.companyInfo.button && this.companyInfo.button.background ? this.companyInfo.button.background : '#61dafb',
                },
            };
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        },
        loadScript(src) {
            return new Promise((resolve) => {
                const script = document.createElement('script');
                script.src = src;
                script.onload = () => {
                    resolve(true);
                };
                script.onerror = () => {
                    resolve(false);
                };
                document.body.appendChild(script);
            });
        },
       async validatePaymentRequest(pgInfo) {
            this.loading = true;
            this.loadingText = "Verifying ...... Please wait ......";
            this.userEnteredAmount = this.balanceAmount;
            if(!pgInfo)
            {
                this.loading = false;
                return ;
            }
            this.transactionAccountId= pgInfo //Assigned Selected Gateway
            if (this.pgAccountInfo && this.pgAccountInfo._id) {
                    let accountInfo = await this.pgAccountInfo.cred_details.filter((pg) => {
                    return pg._id == this.transactionAccountId
                    });
                        if (accountInfo && accountInfo[0] && accountInfo[0]._id) {
                            this.activePaymentSystem = accountInfo ? accountInfo[0] : null;
                            let decrypted = this.$CryptoJS.AES.decrypt(this.activePaymentSystem.crypto, this.activePaymentSystem.ivKey);
                            let keys = JSON.parse(decrypted.toString(this.$CryptoJS.enc.Utf8));
                            this.activePaymentSystem = { ...this.activePaymentSystem, ...keys, paymentAccountId: this.$route.params.paymentAccountId, transactionAccountId: this.$route.params.transactionAccountId };
                        }
            }
            
            if (this.payButtonDetails && this.payButtonDetails.validations && this.payButtonDetails.validations.partialPayment) {
                this.partialPaymentVisible = true;
                // this.modalTitle = `Payment initiation  of ${this.data.validations.currency} ${this.tempStorage.amount}`
                this.payButtonDetails.validations.currentTxnAmount = this.transactionInfo.amount;
                this.loading = false;
                this.loadingText = "";
            }
            else {
                this.partialPaymentVisible = false;
                this.payInitiate();
            }
        },
        payInitiate() {
            this.partialPaymentVisible = false;
            this.loading = true;
            this.loadingText = "Loading Gateway Configuration..... Please wait....."
            if (this.activePaymentSystem && this.activePaymentSystem.pgType && this.activePaymentSystem.pgType == 'RAZORPAY') {
                this.razorPaySettings();
            }
            else {
                this.otherPayments = true;
                this.loading = false;
            }
        },
        handleClose() {
            this.$confirm("Are you sure to cancel the transaction? ", "Warning", {
                confirmButtonText: "Yes",
                cancelButtonText: "No",
            }).then(async () => {
                this.loading = true;
                this.partialPaymentVisible = false;
                // this.payButtonDetails.validations.payAmount = this.data.validations.currentTxnAmount;
                this.loading = false;
                // this.historyPopUp = false;
                // this.modalTitle = '';
                // this.loading = false;
            }).catch(err => {
                console.log("handleClose",err)
                // An error occurred
            });
        },
        handleCancel() {
            this.loading = true;
            this.partialPaymentVisible = false;
            this.otherPayments = false;
            this.loading = false;
            this.loading = false;

        },

        async transactionsInfo(data, templateId, key, referenceKey) {
            this.loading = true;
            this.loadingText = "Fetching transaction history ...... Please wait ......";
            let templatesData = data && data.templates_data;

            let dataSet = await templatesData.filter((data) => {
                return data.template_id == templateId;
            });
            if (dataSet && templateId && dataSet[0] && dataSet[0].template_data_id && dataSet[0].template_data_id.template_data && referenceKey) {
                this.lastTransactions = dataSet[0].template_data_id.template_data[key + '_history'] ? dataSet[0].template_data_id.template_data[key + '_history'] : [];
                this.totalPaymentAmount = referenceKey && dataSet[0].template_data_id.template_data[referenceKey] ? dataSet[0].template_data_id.template_data[referenceKey] : 0;
                this.userEnteredAmount = referenceKey && dataSet[0].template_data_id.template_data[referenceKey] ? dataSet[0].template_data_id.template_data[referenceKey] : 0;
                let amounts = this.lastTransactions.map(txn => {
                    return parseFloat(txn.paidAmount, 2);
                })
                let sum = amounts.reduce((accumulator, currentValue) => {
                    return accumulator + currentValue;
                }, 0);
                this.totalPaidAmount = sum || 0;
                this.balanceAmount = parseFloat(this.totalPaymentAmount - this.totalPaidAmount, 2);
                this.loading = false;
                this.loadingText = "";

            }
            else {
                this.loading = false;
                this.loadingText = "";
            }

        },

        //Common Gateway Apis calls
        async commonCCPayment() {
            try {
                this.loading = true;
                this.loadingText = "Establishing the secure connection..... Please wait.... "
                this.customer.email = this.email;
                this.customer.env= this.activePaymentSystem && this.activePaymentSystem.isLive?1:0;
                this.ccPayMethod.env= this.activePaymentSystem && this.activePaymentSystem.isLive?1:0;
                this.checkoutInfo.env= this.activePaymentSystem && this.activePaymentSystem.isLive?1:0;
                let customer = await this.createCustomer(this.customer);
                if (!customer.status) {
                    this.loading = false;
                    this.loadingText = "Please wait.... ";
                    this.errorPopup(customer.message, "Customer  Creation Failed");
                    return;
                }
                else {
                    let customerToken = customer.result.customer_token;
                    this.ccPayMethod.cstoken = customerToken;
                    this.customer.customerId = customerToken;
                    this.checkoutInfo.customerId = customerToken;
                    this.ccPayMethod.billing_address = {
                        city: this.cust_city,
                        countryCode: this.country,
                        state: this.cust_state,
                        street: this.cust_street,
                        postalCode: this.zipCode
                    }
                    let payMethod = await this.createCCPayMethod(this.ccPayMethod);
                    if (!payMethod.status) {
                        this.loading = false;
                        this.loadingText = "Please wait.... ";
                        this.errorPopup(payMethod.message, "Pay method  Creation Failed");
                        return;
                    }
                    console.log(payMethod)
                    let payMethodToken = payMethod.result.paymethod_token;
                    this.ccPayMethod.payMethodId = payMethod.result.paymethod_token;
                    this.checkoutInfo.currencyCode =this.transactionInfo.currency;
                    if (this.activePaymentSystem && this.activePaymentSystem.pgType && this.activePaymentSystem.pgType == 'SWIREPAY' && this.transactionInfo.currency !='INR' ) {
                        let setupSessionData = {
                            env: this.activePaymentSystem && this.activePaymentSystem.isLive?1:0,
                            orgId: '',
                            locId: '',
                            partnerAccountId: '',
                            currencyCode: this.transactionInfo.currency,
                            cardType: 'CARD',
                            paymentToken: payMethodToken
                        }

                        this.payMethodValidationLink = '';

                        let setupSessionInfo = await this.setupSession(setupSessionData);
                        if (!setupSessionInfo.status) {
                            this.loading = false;
                            this.loadingText = "Please wait.... ";
                            this.errorPopup(setupSessionInfo.message, " Failed to setup a session");
                            return;
                        }

                        
                        if (setupSessionInfo.result.status == "VERIFIED") {
                            this.checkoutInfo.paymethod_token = payMethodToken;
                            this.checkoutInfo.authorization_amount = this.userEnteredAmount * 100;
                            this.checkoutInfo.action = 'CARD';
                            

                            //this.checkoutInfo.action='ACH_LEGACY'; //Swirepay ACH

                            this.proceedToPayment(this.checkoutInfo);
                        }
                        else {
                            // Verify the status trough Action Url
                            this.checkoutInfo.paymethod_token = payMethodToken;
                            this.payMethodValidationPopUp = true;
                            this.payMethodValidationLink = setupSessionInfo.result.nextActionUrl;
                            this.loading = false;

                        }
                    }
                    else {
                        this.checkoutInfo.paymethod_token = payMethodToken;
                        this.checkoutInfo.authorization_amount =  this.activePaymentSystem.pgType == 'SWIREPAY'?this.userEnteredAmount * 100:this.userEnteredAmount; //Swirepay ACH
                        this.checkoutInfo.action = this.activePaymentSystem.pgType == 'SWIREPAY'?'CARD':'sale';
                        this.checkoutInfo.sec_code = this.activePaymentSystem.pgType == 'SWIREPAY'?'':'WEB';
                        // this.checkoutInfo.paymentType='echeck'  for ach fortee
                        this.proceedToPayment(this.checkoutInfo);
                    }
                }
            }
            catch (err) {
                console.log("CC Payment ", err)
            }
        },



        async proceedToPayment(data) {
            try {
                let transaction = await this.paymentInitiate(data);
                if (!transaction.status) {
                    this.loading = false;
                    this.loadingText = "Please wait.... ";
                    this.errorPopup(transaction.message, "Transaction Failed");
                    return;
                }
                let transactionId = transaction.result.transaction_id;

                let getTxnStatus = await this.verifyTransaction(transactionId);
                if (!getTxnStatus.status) {
                    this.loading = false;
                    this.loadingText = "Please wait.... ";
                    this.errorPopup(transaction.message, "Transaction Failed");
                    return;
                }
                if (getTxnStatus.pgResponse === 'FORTE') {
                    console.log("PG TYPE",getTxnStatus.pgResponse)
                    this.addTransactionToVerify(getTxnStatus.result, getTxnStatus.pgResponse)
                }
                else if (getTxnStatus && getTxnStatus.result && getTxnStatus.result.response && getTxnStatus.result.response.response_desc === 'SUCCEEDED') {
                    this.paymentStatusUpdate(getTxnStatus.result)
                }
            }
            catch (err) {
                console.log("proceedToPayment",err)
                this.loading = false;
                this.loadingText = "Please wait.... ";
                this.errorPopup(err.message, "Error");

            }
        },


        async paymentInitiate(data) {
            try {
                this.loading = true;
                this.loadingText = "Initiating the transaction....... Please wait.....";
                const paymentCheckout = await axios.post(`${config.PAYMENT_GATEWAY_URL}/initiatePayment?pgAccountId=${this.activePaymentSystem.paymentAccountId}&txnAcId=${this.transactionAccountId}`, data);
                return { ...paymentCheckout.data, status: true };
            }
            catch (err) {

                console.log("paymentInitiate",err)
                return { status: false, message: err.response.data.data.error.response.response_desc };
            }
        },

        async verifyTransaction(txnId) {
            try {
                this.loading = true;
                this.loadingText = "Verifying the transaction....... Please wait.....";
                let payload = {
                    env: this.activePaymentSystem && this.activePaymentSystem.isLive?1:0,
                    orgId: "",
                    locId: "",
                    trnId: txnId
                }
                const transactionInfo = await axios.post(`${config.PAYMENT_GATEWAY_URL}/getTransactionById?pgAccountId=${this.activePaymentSystem.paymentAccountId}&txnAcId=${this.transactionAccountId}`, payload);
                return { ...transactionInfo.data, status: true }
            }
            catch (err) {
                console.log("verifyTransaction",err)
                return { status: false, message: err.response.data.data.error.response.response_desc };
            }

        },

        async createCustomer(customerData) {
            try {
                this.loading = true;
                this.loadingText = "Verifying the user details....... Please wait.....";
                const customer = await axios.post(`${config.PAYMENT_GATEWAY_URL}/createCustomer?pgAccountId=${this.activePaymentSystem.paymentAccountId}&txnAcId=${this.transactionAccountId}`, customerData);
                return { ...customer.data, status: true };
            }
            catch (err) {
                return { status: false, message: err.response.data.data.error.response.response_desc };
            }

        },
        async createCCPayMethod(payMethodData) {
            try {
                this.loading = true;
                this.loadingText = "Validating the card details....... Please wait.....";
                const paymethod = await axios.post(`${config.PAYMENT_GATEWAY_URL}/createCCPaymethodToCustomer?pgAccountId=${this.activePaymentSystem.paymentAccountId}&txnAcId=${this.transactionAccountId}`, payMethodData);
                return { ...paymethod.data, status: true };
            }
            catch (err) {
                console.log("createCCPayMethod",err)
                return { status: false, message: err.response.data.data.error.response.response_desc };
            }
        },

        async validateSession() {
            try {
                let setupSessionData = {
                    env: this.activePaymentSystem && this.activePaymentSystem.isLive?1:0,
                    orgId: '',
                    locId: '',
                    partnerAccountId: '',
                    currencyCode: this.transactionInfo.currency,
                    cardType: 'CARD',
                    paymentToken: this.checkoutInfo.paymethod_token
                }

                let setupSessionInfo = await this.setupSession(setupSessionData);
                if (!setupSessionInfo.status) {
                    this.loading = false;
                    this.loadingText = "Please wait.... ";
                    this.errorPopup(setupSessionInfo.message, "Error");
                }

                if (setupSessionInfo.result.status == "VERIFIED") {
                    this.checkoutInfo.authorization_amount = this.userEnteredAmount * 100;
                    this.checkoutInfo.action = 'CARD';
                    this.proceedToPayment(this.checkoutInfo);
                }
                else {
                    // Still not yet verified.

                    this.payMethodValidationLink = setupSessionInfo.result.nextActionUrl;
                    this.loading = false;
                    this.$bvModal.msgBoxOk('Still Not  Verified! Please verify  your CC', {
                        title: 'Info',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        headerClass: 'p-2 border-bottom-0',
                        footerClass: 'p-2 border-top-0',
                        centered: true
                    })
                        .then(value => {
                            // this.boxTwo = value
                            console.log(value)
                        })
                        .catch(err => {
                            console.log("validateSession",err)
                            // An error occurred
                        })
                }
            }
            catch (err) {
                console.log("validateSession",err)
                this.loading = false;
                this.loadingText = "Please wait.... ";
                this.errorPopup(err.message, "Error");
            }
        },

        async setupSession(data) {
            try {
                this.loading = true;
                this.loadingText = "Establishing the connection....... Please wait.....";
                const setupSession = await axios.post(`${config.PAYMENT_GATEWAY_URL}/setupSession?pgAccountId=${this.activePaymentSystem.paymentAccountId}&txnAcId=${this.transactionAccountId}`, data);
                return { ...setupSession.data, status: true }
            }
            catch (err) {
                console.log("setupSession",err)
                return { status: false, message: err.response.data.data.error.response.response_desc };
            }


        },

        //common apis  for both cc and echeck end

        async paymentStatusUpdate(result, paymethod, customerId) {
            try {
                this.loading = true;
                this.loadingText = "Updating the payment status....... Please wait.....";

                let paymentObj = {
                    txn_id: result.other.receiptNumber,
                    gateway: "SWIREPAY",
                    paymentId: result.other.gid,
                    name: result.other.customer.name,
                    email: result.other.customer.email,
                    phoneNumber: result.other.customer.phoneNumber ? result.other.customer.phoneNumber : "+19999999999",
                    paymentSession: result.other.psClientSecret,
                    amount: result.other.amount,
                    amountRefunded: result.other.amountRefunded,
                    description: "Payment Trough Link",
                    paymentDate: result.other.createdAt,
                    refundDate: result.other.refundDate,
                    status: result.other.status,
                    paymentType: result.other.paymentMethod.paymentType.category,
                    method: "",
                    currency: result.other.currency.name,
                    payMethod: paymethod,
                    customerId: customerId,
                    pgAccId: this.activePaymentSystem.paymentAccountId,
                    txnAcId: this.transactionAccountId,
                    //   autoPay: formData.automaticPay
                }
                let finalObj = {
                    customer: {
                        id: result.other.gid,
                        amount: (result.other.amount) / 100,
                        authCode: result.other.authCode,
                        authorizationId: result.other.authorizationId,
                        psClientSecret: result.other.psClientSecret,
                        customer: result.other.customer.gid,
                        customerEmail: result.other.customer.email,
                        customerName: result.other.customer.name,
                        payMethod: result.other.paymentMethod.gid,
                        pgAccId: this.activePaymentSystem.paymentAccountId,
                        txnAcId: this.transactionAccountId,
                    },
                    txnDetails: paymentObj,
                    amount: this.totalPaymentAmount,
                    formBuilderId: "",
                    currency: this.transactionInfo.currency,
                    entityId: "",
                    location: "",
                    type: "",
                    templateId: this.templateId,
                    paymentInit: this.userEnteredAmount,
                    entityDataId: this.dataId,
                    key: this.transactionInfo.key,
                    updateDataKey: this.transactionInfo.updateDataKey,
                    formBuilderDataId: "",
                    status: "SUCCESS",
                }
                const apiResponse = await axios.post(`${config.API_URL}/updateRulesOfPayBtn`, finalObj, {
                    headers: {
                        usertimezone: new Date().getTimezoneOffset()
                    }
                });
                if (apiResponse.data) {

                    //Api data updated
                    this.partialPaymentVisible = false;
                    this.partialPaymentStatus = 1;
                    this.userEnteredAmount = 0;
                    document.getElementById("transactionReferenceId").innerHTML = paymentObj.txn_id;
                    document.getElementById("statusMsg").innerHTML='Paid';
                    let inProgress = `
                        <p style="text-align:justify">We're excited to inform you that your payment has been successfully processed! 🚀</p>
                        <br /> 
                        <table style="width: 100%;border: 1px solid #784596;"><tr><th style="border: 1px solid #784596;" colspan="2">Payment Details </th></tr>
                           <tr><th style="border: 1px solid #784596;"> Amount</th><td style="border: 1px solid #784596;"> ${paymentObj.currency} ${ paymentObj.amount /100} </td></tr>
                            <tr><th style="border: 1px solid #784596;">  Transaction ID</th><td style="border: 1px solid #784596;">${ paymentObj.paymentId}</td></tr>
                            <tr><th style="border: 1px solid #784596;">  Payment Method </th><td style="border: 1px solid #784596;"> ${ paymentObj.paymentType}</td></tr>
                            </table>
                            <br/>
                        <p style="text-align:justify">Thank you for choosing us! Your support means the world to us. If you have any questions or need further assistance, feel free to reach out. Our team is here to help!</p>
                        `;
                    document.getElementById("transactionInfoMsg").innerHTML = inProgress;

                    document.getElementById("paymentBox").style.display = "none";
                    document.getElementById("receipt").style.display = "block";
                    setTimeout(() => {
                        this.loading = false;
                        this.loadingText = "";
                    }, 3000);

                }
            }
            catch (err) {
                console.log("paymentStatusUpdate",err)
            }
        },
        async addTransactionToVerify(result, pgType) {
            let transactionDetails = {
                pgType: pgType,
                source: config.PAYMENT_GATEWAY_SOURCE_KEY,
                pgId: this.paymentAccountId,
                txnACId: this.transactionAccountId,
                txnId: result.transaction_id,
                isCompleted: false,
                status: "READY",
                current_status: "READY",
                partnerAccountId: null,
                otherInfo: {
                    usertimezone: new Date().getTimezoneOffset(),
                    paymethod: this.checkoutInfo.paymethod_token,
                    customerId: this.customer.customerId,
                    email: this.email,
                },
                encryptedData: this.$route.params.transactionCode
            };
            const apiResponse = await axios.post(`${config.PAYMENT_GATEWAY_URL}/add_transaction`, transactionDetails, {
                headers: {
                    usertimezone: new Date().getTimezoneOffset()
                }
            });
            this.partialPaymentVisible = false;
            this.partialPaymentStatus = 1;
            this.userEnteredAmount = 0;
            document.getElementById("transactionReferenceId").innerHTML = apiResponse.data.txnId;
            document.getElementById("statusMsg").innerHTML='Initiated';
            let inProgress = `
                        <p style="text-align:justify">Thank you for submitting your payment request. <br/> We would like to inform you that your transaction is currently in <b>Initiated</b> and we are working diligently to ensure that it is processed as soon as possible. </p>
                        <p style="text-align:justify">
                            To help you track your transaction, we have assigned a unique reference number -  <b> ${apiResponse.data.reference_id}</b>, which you can use for future inquiries. Additionally, your transaction ID is also ${apiResponse.data.txnId}.  Thank you!
                        </p>`;
            document.getElementById("transactionInfoMsg").innerHTML = inProgress;
            document.getElementById("paymentBox").style.display = "none";
            document.getElementById("receipt").style.display = "block";
            setTimeout(() => {
                this.loading = false;
                this.loadingText = "";
            }, 3000);
        }
    },
    async mounted() {

        document.getElementById("paymentBox").style.display = "block";
        document.getElementById("receipt").style.display = "none";
        const loader = document.getElementById("overlay");
        loader.style.display = "none";
        this.loading = true;
        this.themeLoader = true;
        this.loadingText = "Loading Transaction History ...Please Wait";
        this.paymentAccountId = this.$route && this.$route.params && this.$route.params.paymentAccountId;
        if (this.paymentAccountId ) {
            //fetching the payment gate information
            this.fetchGatewayInfo(this.paymentAccountId);
        }
        let transactionCode = this.$route && this.$route.params && this.$route.params.transactionCode;
        let reb64 = this.$CryptoJS.enc.Hex.parse(transactionCode);
        let bytes = reb64.toString(this.$CryptoJS.enc.Base64);
        let decrypt = this.$CryptoJS.AES.decrypt(bytes, 'paymentLink');
        let plain = decrypt.toString(this.$CryptoJS.enc.Utf8);
        this.transactionInfo = JSON.parse(plain);
        this.dataId = this.transactionInfo && this.transactionInfo.entityDataId;
        this.entityId = this.transactionInfo && this.transactionInfo.entityId;
        this.templateId = this.transactionInfo && this.transactionInfo.templateId;
        if (this.dataId && this.entityId) {
            await Promise.all([
                this.callEntityApi(this.entityId),
                this.getLocation(),
                this.callEntityDataApi(this.dataId),
            ]);
        }
    }
};
</script>

<style lang="scss" scoped>
.info-table{
    width:100% ;
}
table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
    border-style:solid;
    border-color: #96D4D4;
  }

.stamp {
    transform: rotate(12deg);
    color: #555;
    font-size: 3rem;
    font-weight: 700;
    border: 0.25rem solid #555;
    display: inline-block;
    padding: 0.25rem 1rem;
    text-transform: uppercase;
    border-radius: 1rem;
    font-family: 'Courier';
    -webkit-mask-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/8399/grunge.png');
    -webkit-mask-size: 944px 604px;
    mix-blend-mode: multiply;
}

.is-nope {
    color: #D23;
    border: 0.5rem double #D23;
    transform: rotate(3deg);
    -webkit-mask-position: 2rem 3rem;
    font-size: 2rem;
}

.is-approved {
    color: #0A9928;
    border: 0.5rem solid #0A9928;
    -webkit-mask-position: 13rem 6rem;
    transform: rotate(-14deg);
    border-radius: 50%;
}

.is-draft {
    color: #C4C4C4;
    border: 1rem double #C4C4C4;
    transform: rotate(-5deg);
    font-size: 6rem;
    font-family: "Open sans", Helvetica, Arial, sans-serif;
    border-radius: 0;
    padding: 0.5rem;
}

#overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    cursor: pointer;
    z-index: 999999999999999999 !important;
}

.loader {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -20px;
    margin-top: -20px;
    z-index: 9999999999999999;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Hide content initially */


.payment-link-card {

    //Old stayles
    .amex {
        background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAeCAMAAABdem3zAAAAA3NCSVQICAjb4U/gAAACi1BMVEUAAAAAAAAAdKIAdqcGdqoDeqkDeKoDe6sFeqoFeqwCeqoGe6wGeasGeqwGe6wFeqwFeqwFeqsGe6oFeawEeqwEeqwFe6wHeaoFe6oFeasFe6wFeawHe6wIfKwJfKwKfa0Lfa0Mfq0Of64Pf64QgK8RgK8Sga8TgbAUgrAVgrAWg7EXg7EYhLEZhLIahbIbhbIdhrMfh7QgiLQhiLQjirUkirUli7YnjLYojLcqjbcsj7gtj7kukLkvkLkwkbkxkboykrozkro0k7s1k7s2lLs3lLw4lbw5lbw6lr07lr08l709l75Amb9Bmr9Dm8BFnMBHncFIncFJnsJKnsJLn8JMn8NNoMNOocRPocRQosRRosVUpMZVpMZWpcZXpcdYpsdZp8dap8dbqMheqclgqslhq8pjrMpkrMtnrsxpr8xqr81tsc5vss5wss9xs89ztNB0tdB1ttF6uNJ8udN9utN+utR/u9SAu9SBvNWCvNWDvdWEvdWGvtaHv9aIv9eKwNeMwdiPw9mQw9mRxNqTxdqUxtuVx9uWx9yXyNyYyNyZyd2ayd2byt2cyt6dy96fzN+gzN+hzd+izeCjzuCkzuCn0OGp0eKq0eKr0uOs0+Ot0+Ov1OSw1eSy1uWz1uW01+W32Oa62ui72+i82+i+3Om/3enC3urE3+vF4OvH4ezI4uzJ4u3K4+3L4+3N5O7O5e7P5e/R5u/S5/DT5/DV6PHW6fHX6fHY6vHa6/Lb7PPc7PPd7fPe7fTf7vTg7vTi7/Xj8PXk8fbm8vbn8vfo8/fp8/fq9Pjr9Pjs9fjt9fnu9vnv9vnw9/rx9/ry+Prz+Pv0+fv1+fv2+vz4+/z5+/37/P38/f7+/v7///+B6xdgAAAAHHRSTlMAARYaJ0FIT1pcYG6YmZyssrPDys3T2tvt9PX+1nJQbwAAAnFJREFUOMtjYOAWESMWiAqwMzBwyZAEOBn4SdMgzCBImgYJUjVI0UeDkoGBrq6BgZ6MhgECqAA56nJ6ICZIWN3AQAeuoevIrvOHDuy6ZLl1366ru3ft2nVl167dJ08cOXHo/P6Dl3Yd33Nm15mdJw+thGnQO2ei2nzDRaZp405Zmd2KxhYWW2TMTeUmJOWv0NOPKVJ1uNEi4329LByuoXKaabvZNZcQw8u5IUANrYuX7pA5eNSxJCk/OPfGBe2ZKotbnAw6kTSs8Axslpnh0mtRr74YqME7LGaHjI6G4uakfOfGG21q3c5hLf7TNDMQGhqUMjN9vFz6O2TCjgA11M+Zs13m4oXIvKT8bOs+i7DMNJks/xuhcggNKQ3b+vfGpS65kLTqVNyRpLi4uP1xl6d09jRPPF+blHC29WB+SsX5PXF1cA0lE/1lWiZOnFg2saZrIgxkgojiyr6JZTLxQFZ5ycSJpRTHdOAmMMiM2Agk103esGnTxiWzwELTVwOJyes29aFqiFtrCQR+x05FuVpaWqcfA3I8FlQDyandjpaWh5KtLI3RNCxTA8ZypHewb7vNrvWKk2QW7wiIzU3YteusXtXWrQvllm+diK5BRl6+4JyW2omJ2qkRiqtknN2VF+UCxWbmKCi5b3GU1fRE16B+4cK5RCe3pH6z6bP3nZOZsyYoMzftwsWrp4+skZt/4kA1mqfjVqgAgcORw/Z23kejg86r7JxXm1AIFOqzVdFLAEoahaNqiDgMBplZQGKNjC6QbD0MA3vmAomN5XTLcaQASQZe0jSIM3CQpoGPgZFHmgT1QkwMDAzMrOxEAjYWBgYAvI9h1MHdhQIAAAAASUVORK5CYII=") #fff;
    }

    .visa {
        background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAeCAMAAABdem3zAAAAA3NCSVQICAjb4U/gAAABvFBMVEUAAADQ0NDa2tra2trZ2dnY2Nja2trt7e3t7e0mM3onNHspNXkqN30rN30sOH4tN3ovO4AwPIAyPoE1QYM3Q4Q4Q4U4RIU5RYY8R4g9SIhCTYtDToxGUY5HUo5JU49JVJBOWJJQW5RSXJVTXZZVX5dXYZhYYplaY5pfaJ1kbaBlbqFoaZFocaNpcqNqc6RtdqZvd6dzcpV0fKp2f6x5ga18g698hK99hK99hbB+hrCAh7GDi7OHjrWIj7aJkLeNk7mNlLqOlbqRl7yUmr6WnL6YnsCbocKepMSjqMekqceprsqrsMysscytss2uss2xts+xttC0uNG1udK1utK2utK3u9O6vdS7v9W8wNa9wda9wdfBxNnDx9rEx9vFyNzFydvHy93Kzd/Mz+DR0+LS1OPT1uTVnV/V1+XX2ebY2NjZuJbZ2+faoVza3Ojc3+rf4evf4ezi5O7j5e7n6fHp6/Lq6/Lr7PPsmC3snTfs7fPunjnu7/Tu7/Xw8fbx8vfy8/f09fj09fn19vn29/r3z5332LH39/r42LD42bL42bP5+fv76tX77dz7+/v7+/387dv9/f7+9ev//v3///9+dhG/AAAACXRSTlMAGxuq7e7u+vsOT6YMAAABbklEQVQ4y+WUV1cTYRQAlwSIsxoLltgLKgZ7AwV777FiL9gT1x4FGxpb0Gg0On/YBx83D+wz8z7nu+fe800QpNKtpTHSmk4FQUt7pu4YqWfaW4L0BBOQSQdt9SRCvS0omYjSOBZ+fB0d/f5T/VQoDHi6cF4b1/Zt6d9fUZ+cLFyMvfDny6vhN3/1EOwegW4/LAHgpb6bBpNr8ZE2PBz+rQvIvrgJ2+2DdaeOba7pXoBbceHAxHvfHIRNHoHjLobLqlZnkIeDcaHIlAeuh6Jb4bb9EG58rh6G4nTWNNnSHFYNZcnrcsKK1d4Qpl63MY9lrmRmE6GHcCdc0Q7mqt5ZAfM9C7uKvfA0LlyASSzUt7Daz+pIyGw7+c+JuPAxCxzV+7DHrq5tOzqhbxA6crlcSE+TS+dhVk0vwRk7AFhb64a76lIWva7EhKEoKqvvo6jqs6sD526UNYoeq5ajR78a4/k/JM5M4pAlTGUqSBrjf5znrWNE0ZcCAAAAAElFTkSuQmCC") #fff;
    }

    .mastercard {
        background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAeCAMAAABdem3zAAAAA3NCSVQICAjb4U/gAAACc1BMVEUAAADQ0NDa2tra2trZ2dnY2Nja2trt7e3t7e3MAADMAQHMBATNCQnOCwvODAzODg7PDwnPERHRGxvSFgfSHh7SHx/SIB7THhDTJCTTJibTJyfUKSnVIAXVLS3VMDDWMjLWNTXWNjbXIQDXLyLXNS7XNzfXOzvYLxbYPT3YPj7Y2NjZOy/ZRUXaSEjaSUnbLQDbLgDbS0vbTU3cSj/cU1PdNADdSjTdVVXdVlbeNwDeW1vfYmLgUDPgZWXgZmbgaGjhXkvhamrhbW3ia2Lib2/jXDfja1njdXXkeHjkeXnke3vlgIDlgYHmg4PmhITmhobnh4fniIjni4voVgDojIzokJDqXADqaiTqlpbqmJjqmZnqmprrnJzrn5/tpqbuqqrura3urq7vsbHvsrLvs7PwbADwbQDwtLTwtbXwt7fxvLzycgDyjULyvr7yv7/zdQDzmVvzn2fzxMTzxcXzx8f2fwD21tb3gQD3x6/3ybL32Nj4hAD43t7439/44OD5iQD54eH54uL65ub65+f76+v7+/v88vL89PT99/f9+Pj9+fn+lwD+/f3/mQD/mgT/nQv/nw//oRT/oRX/oRb/ohj/qCf/qSn/qSr/qy3/rDH/rjX/rjb/sT7/sj//s0L/tEX/tUf/tUj/tkn/t0v/uVD/uVH/u1X/vFj/vVr/vl7/v2H/w2n/xGz/x3P/yHb/yXr/zob/z4j/0Iv/1Zj/1pr/153/2J7/26X/3q7/4LL/4LP/4bX/4bb/5cD/5sL/58P/58T/58X/6sz/7NH/7dL/8d7/8t//9OX/9eb/9ef/9ur/9+v/+vT/+/X//Pj//fz///90HdR0AAAACXRSTlMAGxuq7e7u+vsOT6YMAAABmElEQVQ4y2NgYGJm0SISsDAzMTAwsrG3XiAStLKzMTIwc1wgAbAzM7C2kqKhlZVB6wJJQItSDS3R5orSmo7pPUD2+d2r506bvWzLKdwaOr14OSFAMuXCzqm9ENC//hwODc2KnHDAVdCLALNOYNXQLo9QzylgGoykY+YZbBqckNSrpKamdSPpWINFQw03kgZhJSUlSyQNfUcxNfjzyfFISUDUi5WCQO+EOZOgGmZswNSgY3VBpyPOxJZf1d4uWdxZW9k45+SBtStWTVowffH8o/MxNUgHNsY0entmxrW5R6VnhNb6NlVu6p247uCOs3sOH941DYuG9MTa3JCiGp+S+CzdrrziBOuK5b1L9x8/tG3vko0bsWjQafCKaIhNqon0qyvzqApyKZMtPLZl8/bTR1Zv3Xd6JRYnBeiJChkJGqppWIgoKKi7mTnIVC9YPHnhnHlTJiyaM3EDgWANz87OLicQrCgRx6VvYJBPIOJQk4ZNWD3BpIGS+DhdCSc+0pM3JAOpkpCBaJOnSS5mSC7ISCwqgYUriYUxAINRRW57ksG5AAAAAElFTkSuQmCC") #fff;
    }

    .discover {
        background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAeCAMAAABdem3zAAAAA3NCSVQICAjb4U/gAAACLlBMVEUAAADQ0NDa2tra2trZ2dnY2Nja2trt7e3t7e3vzbDvzbEBAQECAgIDAwMTExMUFBQWFhYYGBgZGRkeHh4jIyMmJiYnJycpKSksLCwtLS0uLi4wMDAzMzM0NDQ3Nzc6Ojo8PDw/Pz9CQkJDQ0NHR0dJSUlKSkpMTExOTk5PT09RUVFWVlZYWFhcXFxgYGBiYmJjY2Nra2tsbGxtbW1wcHBxcXF0dHR1dXV2dnZ3d3d4eHh8fHx9fX1+fn6AgICBgYGCgoKDg4OLi4uMjIyPj4+VlZWWlpabm5udnZ2enp6fn5+hoaGjo6OoqKirq6usrKyvr6+wsLCysrKzs7O4uLi7u7u8vLy9vb2/v7/AwMDBwcHExMTGxsbHx8fJycnLy8vQ0NDR0dHS0tLU1NTW1tbY2NjZ2dnb29vd3d3f39/h4eHi4uLn5+fo6Ojp6enr6+vs7Ozt7e3v7+/x8fHy8vL1giD1giH1gyP1hCT1iS31ii71izD1jDL2kTv2kjz2kz/2lED2lkP2lkT2l0X2mUn2pmH2pmL3m033n1X3oVf3pF73pV739/f4q2n4q2r4rWz4r3D4r3H4sXT4s3f5uoT5u4b5vov5+fn6xJb6yJ36yqD6zKT6+vr7zqj70a372Ln7+/v83sT838b84Mj84sv848785M/85dD89O78/Pz959X96Nb969z9/f3+8+r+9e7+9u/+9/H++PP++vb++vf+/Pn+/Pr+/fz+/v7////OeAUcAAAAC3RSTlMAGxuq7e7u+vv7+w/+RoMAAAGnSURBVDjLY2BgYmZJJRKwMDMxMDCysS/YSSRYwM7GyMDMsZMEwM7MwLqAFA0LWBlSd5IEUumkwc7A0HPBTuvcTAN13535FuqWJTsXdrdP2+IZuHOnR6iNgYHVDgMDsySEBin3QE2NnWJpKk6x8T1iDsku6UvKSktL+4Kld84RzBb3Co9ZyRPnJYikIXHnAr5ssTR9hYCVIfIg8UmlILBCOMFfa6e4jWvQWh5/e0kUDTuFMsTSpvvI6gUog8QngzVsdDRVC9spbu7st5bbmL8ASUNEipEi0ElRRQEyhYL+K/2Tl5cD1U/cmSMgsXOneHRe3krenSpuCA26ckp2xTu1s2xFZSJ3JqiJqKbvXNrbOWPrzp0m3jt36sjJyS2Q35ms0rNz53wSg3VRI2kaVlc1kKRhVU0pSRoWVZaSpGF2RSkpGjZNAMcM0RoW15aSomE9JOKJ1bB1VnUpCRq2z6srLSVew+ZZyMoJalgzpbq0lGgNG+Z2lGKAplQcxcy6uV3lpVhAKyuWgmzLwqktpTgAJzNaUblt2cz+xnocoLmNiw1YuJJYGAMAEKBGzN/0FVAAAAAASUVORK5CYII=") #fff;
    }

    /*
    .expiry-date-group {
        float: left;
        width: 50%
    }

    .expiry-date-group input {
        width: calc(100% + 1px);
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .expiry-date-group input:focus {
        position: relative;
        z-index: 10;
    }

    .security-code-group {
        float: right;
        width: 50%
    }

    .security-code-group input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .zip-code-group {
        clear: both;
    }
*/
    #PayButton {
        outline: 0 !important;
        height: 42px;
        font-size: 16px;
        background-color: #54C7C3 !important;
        border: none;
    }

    #PayButton:hover {
        background-color: #6DCECB !important;
    }

    #PayButton:active {
        background-color: #4FBCB9 !important;
    }

    #PayButton:disabled {
        background: rgba(84, 199, 195, .5) !important;
        color: #FFF !important;
    }

    .container {
        margin-top: 50px;
    }

    #Checkout {
        z-index: 100001;
        width: 50%;
        min-width: 300px;
        height: 100%;
        min-height: 100%;
        background: 0 0 #ffffff;
        border-radius: 8px;
        border: 1px solid #dedede;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }

    #Checkout>h1 {
        margin: 0;
        padding: 20px;
        text-align: center;
        background: #EEF2F4;
        color: #5D6F78;
        font-size: 24px;
        font-weight: 300;
        border-bottom: 1px solid #DEDEDE;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    #Checkout>form {
        margin: 0 25px 25px;
    }

    label {
        color: #46545C;
        margin-bottom: 2px;
    }

    .input-container {
        position: relative;
    }

    .input-container input {
        padding-right: 25px;
    }

    .input-container>i,
    a[role="button"] {
        color: #d3d3d3;
        width: 25px;
        height: 30px;
        line-height: 30px;
        font-size: 16px;
        position: absolute;
        top: 2px;
        right: 2px;
        cursor: pointer;
        text-align: center;
    }

    .input-container>i:hover,
    a[role="button"]:hover {
        color: #777;
    }

    .amount-placeholder {
        font-size: 20px;
        height: 34px;
    }

    .amount-placeholder>button {
        float: right;
        width: 60px;
    }

    .amount-placeholder>span {
        line-height: 34px;
    }



    .align-middle {
        vertical-align: middle;
    }

    input {
        box-shadow: none !important;
    }

    input:focus {
        border-color: #b0e5e3 !important;
        background-color: #EEF9F9 !important;
    }
}
</style>
